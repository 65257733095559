<template>
  <v-select
    outlined
    :items="rubros"
    item-text="descripcion"
    item-value="id"
    :label="title"
    return-object
    v-model="selectedRubro"
    @change="handleRubroChange"
  ></v-select>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "Rubro venta",
    },
  },

  data() {
    return {
      selectedRubro: {...this.value},
      rubros: [],
    };
  },
  computed: {
    ...mapState("contabilidad", ["rubrosCompra"]),
  },
  watch: {
    rubrosCompra: {
      handler() {
        this.rubros = this.rubrosCompra;
      },
      immediate: true,
    },
   
  },
  methods: {
    ...mapActions("contabilidad", ["fetch_rubro_compras"]),

    handleRubroChange() {
      console.log("Selected rubro",this.selectedRubro);
      
      this.$emit("process-select", this.selectedRubro);
    },
  },
  created() {
    this.fetch_rubro_compras();
  },
};
</script>
