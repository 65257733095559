<template>
  <v-card class="mx-auto" max-width="500">
    <v-sheet class="pa-4 grey darken-2">
      <v-text-field
        rounded
        outlined
        label="Buscar cuenta por nombre"
        style="background-color: #3a3b3c"
        dense
        clearable
        v-model="search"
        item-text="nombre"
        prepend-inner-icon="mdi-magnify"
        single-line
        hide-details
        autofocus
      ></v-text-field>
    </v-sheet>

    <v-card-text>
      <v-treeview
        :items="planDeCuentas"
        color="warning"
        activatable
        open-all
        item-text="nombre"
        :search="search"
        return-object
       @update:active="setCuenta"
       hoverable
       

      >
        <template #label="{ item }">
          <span @click.right="handleClickRight(item, $event)"
            >{{ item.codigo }} - {{ item.nombre }}</span
          >
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    planDeCuentas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: null,
    };
  },

  methods: {
    setCuenta(cuenta) {
      console.log(cuenta);
      
      this.$emit("update:initialCuenta", cuenta);
    },
    handleClickRight(cuenta, event) {
     console.log(cuenta, event);
     
      event.preventDefault();
     
      this.$emit('process-click-right', { cuenta, event });
    },
  },
};
</script>